import * as React from "react"
import { graphql } from 'gatsby'
import Social from "../components/social.js"
import { Helmet } from "react-helmet"
import ReactMarkdown from "react-markdown"
import Layout from "../components/LayoutAlt.js"

const About = ({data}) => {
  return( 
  <Layout>

  <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Preaching the Everlasting Gospel contained in the 3 Angels Messages ?" />
        <title>ReaCH | About Us</title>
        <link rel="canonical" href="https://reachun.org/about" />
      </Helmet>

  <section className="sm:w-3/4 sm:ml-1/8 mt-20 xs:p-4 sm:p-0">
      <div className="font-montserrat ">
        <h1 className="font-bold text-2xl text-blue-dark text-center lg:text-left">{data.strapiAbout.title}</h1>
        <h3 className="font-bold xs:text-3xl sm:text-4xl text-blue-dark mt-2 text-center lg:text-left">{data.strapiAbout.description}</h3>
      </div>

      

      <main className="mt-10">
        <h2 className="font-montserrat font-bold text-2xl text-blue-dark text-center lg:text-left">{data.strapiAbout.singlecard.title}</h2>
        <h3 className="font-playfairdisplay font-medium text-4xl text-blue-dark mt-2  text-center lg:text-left">{data.strapiAbout.singlecard.description}</h3>

        <div className="">
          <div className="border-8 border-blue-light my-5"></div>
        </div>

        <p className="font-opensans text-gray-700 xs:text-base sm:text-lg tracking-wide mt-4 text-justify lg:text-left articles"><ReactMarkdown children={data.strapiAbout.singlecard.content} /></p>
      </main>

      <div className="">
          <div className="border-4 border-orange-dark w-1/10 my-5"></div>
        </div>

      <article>
         <div className="mt-20">
           {data.strapiAbout.card.map(vm =>(
             <div key={vm.id} className="mb-4 rounded-xl">
               <h2 className="font-montserrat font-bold text-xl text-blue-dark">{vm.title}</h2>
               <h3 className="font-playfairdisplay xs:text-2xl  text-blue-dark mt-2 ">{vm.subtitle}</h3>
               
               <p className="font-opensans text-blue-dark tracking-wider mt-4 text-lg text-justify articles"><ReactMarkdown children={vm.content} /></p>
             </div>
           ))}
         </div>
      </article>
        
        <div className="mb-20">
          <div className="border-4 border-orange-dark my-5 w-1/10"></div>
        </div>
       

     {/*  <article className="my-20">
      <h1 className="font-montserrat font-bold text-xl text-blue-dark">Meet the Team</h1>
        <div className=" flex mt-8">
          {data.strapiAbout.team.map(tm => (
            <div key={tm.id} className="mt-4">
            <div className="flex justify-center">
              <img src={tm.avatar.formats.thumbnail.url} alt="thumbnail" className="w-20 h-20 rounded-full border-4 border-gray-200"/></div>
              <h3 className="text-center font-montserrat font-bold text-sm text-blue-dark mt-2">{tm.name}</h3>
              <p className="text-center font-montserrat text-lg">{tm.description}</p>
            </div>
          ))}
        </div>
      </article> */}
  </section>
  <Social />
  </Layout>
  
  )
}

export const query = graphql`
     query AboutPageQuery{
      strapiAbout {
        id
        title
        description
        singlecard {
          title
          description
          content
        }
        card {
          id
          title
          subtitle
          content
        }
        team {
          id
          name
          description
          avatar {
            formats {
              thumbnail {
                url
              }
            }
          }
        }
      }
     }
`


export default About